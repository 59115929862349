<template>
  <view-wrapper class="host" header-text="Host a screening and discussion for free">
    <p>
      <strong>THE WEST IS BURNING</strong> will open our eyes, hearts, and minds to the conditions of western forests in the US.
    </p>

    <p>
      The film is particularly effective when watched and talked about with others such as classmates, colleagues, family and friends. 
    </p>

    <p>
      We invite you to sign up to host free public screenings of <strong>THE WEST IS BURNING</strong> in your community. With our complimentary viewing guides, we are committed to supporting your discussions and your participation. Thank you for being part of the conversation.
    </p>

    <section class="mt-5">
      <h2>Host a <span class="underline">public</span> screening event</h2>
      <p>Screen <strong>THE WEST IS BURNING</strong> virtually, at your college, organization, drive-in theater, cinema, a place in your community, or get creative!</p>
      <v-btn class="btn-extra-wide" depressed color="accent" :to="{ name: 'public-screening' }">Host Public Screening</v-btn>
    </section>

    <section>
      <h2>Host a <span class="underline">private</span> screening circle</h2>
      <p>
        Invite your friends, family and co-workers to watch <strong>THE WEST IS BURNING</strong> with you and discuss it together.
      </p>
      <v-btn class="btn-extra-wide" depressed color="accent" :to="{ name: 'home-screening' }">Host Private Screening</v-btn>
    </section>

    <section>
      <h2>Hosted Public Screenings</h2>
      <p>To join a public screening, contact the host directly.</p>
      <!-- <p>Already a member of PBS? <a href="https://www.pbs.org/passport/videos/" target="_blank" rel="noopener">Stream on PBS Passport.</a></p> -->
      <screening-list>
        <template slot="no-results">
          <p>
            Don’t see it here? <router-link :to="{ name: 'watch' }">Check out all the other ways to watch the film!</router-link>
          </p>
          <!-- <p>
            Not on your local station? <a href="https://www.pbs.org/stations/" target="_blank" rel="noopener">Contact your PBS station</a> and ask them to show it.
          </p> -->
        </template>
      </screening-list>
    </section>
    <!-- TODO: put in captcha -->
  </view-wrapper>
</template>

<script>
import ScreeningList from '@/components/ScreeningList/ScreeningList'

export default {
  name: 'host',

  components: {
    ScreeningList
  }
}
</script>

<style lang="stylus">
  .host
    h2
      margin-bottom: 5px

    .host__screenings
      margin: 0px
      padding: 0
      list-style: none

    .host__screenings-search
      width: 280px
</style>
